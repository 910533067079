import React from "react";
import Header from "../../components/Header/Header";
import ComingSoon from "../../components/ComingSoon/ComingSoon";

const ParentHome = () => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default ParentHome;

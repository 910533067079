import React, { useEffect, useState } from "react";
import { Avatar, Box } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import About from "./EmergencyContactDetailsSteps/About";
import Contact from "./EmergencyContactDetailsSteps/Contact";
import { useGetSingleParentDetailsQuery } from "../../../redux/features/parentPortal/students/studentsApi";
import useProtected from "../../../hooks/useProtected";

const EmergencyContactDetails = ({ showId }) => {
  //============================ fetching data =======================================//
  const { data, isLoading, error } = useGetSingleParentDetailsQuery(showId, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  useProtected(error);
  const [guardianParentsDetails, setGuardianParentsDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    preferredContact: "",
    homePhone: "",
    workPhone: "",
    address: "",
    occupation: "",
    preferredLanguage: "",
    highestEducation: "",
  });

  useEffect(() => {
    if (data && !isLoading && !error) {
      setGuardianParentsDetails({
        ...guardianParentsDetails,
        firstName: data?.body?.data?.firstName,
        lastName: data?.body?.data?.lastName,
        email: data?.body?.data?.email,
        preferredContact: data?.body?.data?.preferredContact,
        homePhone: data?.body?.data?.homePhone,
        workPhone: data?.body?.data?.workPhone,
        address: data?.body?.data?.address
          ? data?.body?.data?.address?.label
          : "",
        occupation: data?.body?.data?.occupation,
        preferredLanguage: data?.body?.data?.preferredLanguage,
        highestEducation: data?.body?.data?.highestEducation,
      });
    }
  }, [data, isLoading, error]);
  //============================ fetching data =======================================//

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className="H_Tab_c">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="About" {...a11yProps(0)} sx={{ width: "50%" }} />
            <Tab label="Contact" {...a11yProps(1)} sx={{ width: "50%" }} />
          </Tabs>
        </Box>

        <div className="TsbPanelContainer" style={{ padding: "0" }}>
          <CustomTabPanel value={value} index={0}>
            <About guardianParentsDetails={guardianParentsDetails} />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Contact guardianParentsDetails={guardianParentsDetails} />
          </CustomTabPanel>
        </div>
      </div>
    </div>
  );
};

export default EmergencyContactDetails;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import axios from "axios";

import ParentStudentRoutes from "./routing/ParentStudentRoutes";

import "./App.css";

/** public pages start */
import Test from "./components/Test/Test";
import SignIn from "./pages/authentication/SignIn";
import SignUp from "./pages/authentication/SignUp";
import CreateNewPassword from "./pages/authentication/CreateNewPassword";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import Otp from "./pages/authentication/Otp";
import ResetPassword from "./pages/authentication/ResetPassword";
import ForgotPasswordEmailSent from "./pages/authentication/ForgotPasswordEmailSent";
import SignInAddPhoneNumber from "./pages/authentication/SignInAddPhoneNumber";
/** public pages end */

/** Example Pages */
import ListingPage from "../src/pages/example-pages/listing-page/ListingPage";
import FormsExample from "../src/pages/example-pages/forms/Forms";
import ParentPortal from "./pages/parentPortal/ParentPortal";
// import CourseDetails from '../src/pages/example-pages/courses/details/DetailsPage';

/** Components links */
import TableData from "../src/components/Table/TableData";
import SchoolRoute from "./routing/SchoolRoute";
/** Components links */

// ** ------------------------- School Portal start --------------------------------- **//
import MediaLibraryTableView from "./components/MediaLibrary/MediaLibraryTableView";
import AccountVrify from "./pages/authentication/AccountVrify";
// ** ------------------------- School Portal end   --------------------------------- **

import VerifyOtp from "./pages/authentication/VerifyOtp";

import Messages from "./pages/schoolPortal/messages/Messages";
import {
  isTheActionPermitted,
  isTheSectionPermitted,
} from "./hooks/HelperFunctionsPermission";
import NotPermitted from "./pages/authentication/NotPermitted";

// ** ------------------------- Parent Portal end   --------------------------------- **//

import ParentRoute from "./routing/ParentRoute";
import ParentHome from "./pages/parentPortal/ParentHome";
import StudentsListing from "./pages/parentPortal/students/StudentsListing";
import StudentsDetails from "./pages/parentPortal/students/StudentsDetails";
import SchoolRouteWithoutLayout from "./routing/SchoolRouteWithoutLayout";
import ParentRouteWithoutLayout from "./routing/ParentRouteWithoutLayout";
import SettingsPage from "./pages/parentPortal/settings/SettingsPage";

// ** ------------------------- Parent Portal end   --------------------------------- **//

function App() {
  return (
    <Routes>
      {/* ExmaplePages start */}
      {/* <Route path="/" element={<AdminRoute><ListingPage /></AdminRoute>} />     */}
      {/* <Route
        path="/"
        element={
          <SchoolRoute>
            <Attendance />
          </SchoolRoute>
        }
      /> */}
      {/* <Route path="/" element={<ListingPage />} />     */}
      <Route path="/listing-example" element={<ListingPage />} />
      <Route path="/forms" element={<FormsExample />} />
      <Route path="/table" element={<TableData />} />
      <Route path="/test" element={<Test />} />
      {/* ExmaplePages end */}
      {/* public routes start */}
      <Route path="/signin" element={<SignIn />} />
      <Route path="/authentication/signin" element={<SignIn />} />
      {/* <Route path="/sign-up" element={<SignUp />} /> */}
      <Route path="/verify-email" element={<Otp />} />
      <Route path="/verify-otp" element={<VerifyOtp />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/create-new-password" element={<CreateNewPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/add-phone-number" element={<SignInAddPhoneNumber />} />
      <Route path="/email-sent" element={<ForgotPasswordEmailSent />} />
      <Route path="/verified-screen" element={<AccountVrify />} />
      {/* public routes end */}
      {/* Parent Student Portal Start */}
      <Route
        path="/parent-portal"
        element={
          <ParentRoute>
            <ParentPortal />
          </ParentRoute>
        }
      />
      <Route
        path="/"
        element={
          <ParentRoute>
            <ParentHome />
          </ParentRoute>
        }
      />
      <Route
        path="/messages"
        element={
          <ParentRoute>
            <ParentHome />
          </ParentRoute>
        }
      />
      <Route
        path="/students"
        element={
          <ParentRoute>
            <StudentsListing />
          </ParentRoute>
        }
      />

      <Route
        path="/students-details/:id"
        element={
          <ParentRouteWithoutLayout>
            <StudentsDetails />
          </ParentRouteWithoutLayout>
        }
      />
      <Route
        path="/applications"
        element={
          <ParentRoute>
            {" "}
            <ParentHome />{" "}
          </ParentRoute>
        }
      />
      <Route
        path="/calender"
        element={
          <ParentRoute>
            <ParentHome />
          </ParentRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ParentRoute>
            <SettingsPage />
          </ParentRoute>
        }
      />
      <Route path="/school-portal" element={<NotPermitted />} />
      {/* Parent Portal end.  */}
    </Routes>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Status from "../../../../components/Status/Status";
import OverviewTables from "./OverviewTables";
import { useGetSingleStudentDetailsQuery } from "../../../../redux/features/parentPortal/students/studentsApi";
import { useParams } from "react-router-dom";
import useProtected from "../../../../hooks/useProtected";
import { capitalizeWords } from "../../../../hooks/HelperFunctions";

const StudentsDetailsOverview = ({ studentDetails }) => {
  const staticStatustData = [
    {
      Label: "Status",
      SubLabel: capitalizeWords(studentDetails?.status),
      Color: "#41AB37",
      line: true,
    },

    {
      Label: "ID",
      SubLabel: studentDetails?.userUniqueID
        ? studentDetails?.userUniqueID
        : "No data",
      Color: "#7D8597",
    },

    {
      Label: "NYSED ID #",
      SubLabel: studentDetails?.nysedId ? studentDetails?.nysedId : "No data",
      Color: "#7D8597",
    },
  ];

  return (
    <div>
      <Status
        MainTitle={true}
        Title={`${studentDetails?.firstName} ${studentDetails?.lastName}`}
        BackLinkLabel="All students"
        Backlink="/students"
        StaticStatus={true}
        StatusLine={false}
        BottomLine={false}
        staticStatustData={staticStatustData}
      />
      <div>
        <OverviewTables studentDetails={studentDetails} />
      </div>
    </div>
  );
};

export default StudentsDetailsOverview;

import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import TextField from "../../components/Form/TextField/TextField";
import PasswordStrength from "../../components/Form/FieldPassword/PasswordStrength";
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
// import AlrtMessage from '../../../components/AlrtMessage/AlrtMessage';
import Buttons from "../../components/Form/Button/Button";
import "../../assets/css/form.css"
import LogoHeader from "./LogoHeader/LogoHeader";

function CreateNewPassword(props) {

  // const [alertMessage, setAlertMessage] = useState("");
  // const processStudentSignIn = (result) => {
  //   console.log(result);
  //   const response = result.data;
  //   if(response.error){
  //     setAlertSeverity("error");
  //     setAlertMessage("Please enter a strong password");
  //     setAlertOpen(true);
  //   } else{

  //     const userData = {
  //       "id": response.body.id,
  //       "firstName": response.body.first_name,
  //       "lastName": response.body.last_name,
  //       "email": response.body.email,
  //       "image": response.body.image,
  //       // "orgId": response.body.org_id,
  //       "userRole": response.body.user_role,
  //     }

  //     // set user data and user access token
  //     localStorage.setItem('userData', JSON.stringify(userData));
  //     localStorage.setItem('userToken', response.token);
  //     // set user as authorized
  //     localStorage.setItem('userAuthenticated', true);
  //     const userVerified = response?.body?.email_verified_at?.length == 0? false : true;
  //     localStorage.setItem('userVerified', userVerified);
  //     // set user id related data
  //     // localStorage.setItem('orgId', response.body.org_id);
  //     // localStorage.setItem('stuId', response.body.stuId);

  //     // show success message
  //     setAlertSeverity("success");
  //     setAlertMessage("Sign in Success!");
  //     setAlertOpen(true);

  //     setTimeout(() => {
  //       //props.handleClose()
  //       router.reload(window.location.pathname)
  //     }, 2000);
  //   }    

  // }

  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState(true);


  return (
    <>

      <div className="SignContainer">
        <LogoHeader />
        <div className="SignContainerInner">

          <div className="SignForm">
            <h1>Create new password</h1>
            <Grid container spacing={3}>


              {/* <Grid item xs={12} md={12}>
                <AlrtMessage open={alertOpen} message={alertMessage} severity={alertSeverity} closeHandler={alertCloseHandler}/>
            </Grid> */}

              <Grid item xs={12} md={12}>
                <PasswordStrength
                  handlePasswordText={setPassword}
                  handlePasswordValidation={setPasswordValidation}
                  placeholder="Password"
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Buttons
                  label="Create Account"
                  buttonColor="#FFB606"
                  border="#FFB606 1px solid"
                  color="#000000"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  link="/signin"
                //onClick={() => signInHandler(values)}
                />
              </Grid>

            </Grid>
          </div>
        </div>

        <div className="SignFormFooter">
          <SecurityOutlinedIcon />
          <span>SECURE SSL ENCRYPTION</span>
        </div>

      </div>
    </>

  );
}


export default CreateNewPassword;


import React, { useState, useEffect } from "react";

import {
  getDataMap,
  getMonths,
  getYears,
  getDates,
} from "../../../../hooks/HelperFunctions";

import Grid from "@material-ui/core/Grid";
// import TextField from '../../../../../components/Form/TextField/TextField';
import GooglePlaceField from "../../../../components/Form/GooglePlaceField/GooglePlaceField";
// import GooglePlacesAutoField from '../../../../../components/Form/GooglePlaceField/GooglePlacesAutoField';

// import SelectField from '../../../../../components/Form/SelectField/SelectField';
import ComboBox from "../../../../components/Form/ComboBox/ComboBox";

import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import FileUplaoder from "../../../../components/Form/FileUplaoder/FileUplaoder";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import PhoneInputField from "../../../../components/Form/PhoneInputField/PhoneInputField";
import TextField from "../../../../components/Form/TextField/TextField";
import moment from "moment-timezone";
import { useMemo } from "react";
import { countries } from "../../../../assets/data/countries";
import {
  getProfileAvatarFromName,
  mapMonth,
  monthArray,
} from "../../../../utils/helpers";
import { type } from "@testing-library/user-event/dist/type";
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import MediaLibrarySelectMedia from "../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Buttons from "../../../../components/Form/Button/Button";
import Externaluse from "../../../../components/MediaLibrary/Externaluse";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button, Divider, IconButton } from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import "./UpdatePersonalInfo.css";
import MenuThreeDot from "../../../../components/MenuThreeDot/MenuThreeDot";
import SelectField from "../../../../components/Form/SelectField/SelectField";

function UpdatePersonalInfo(props) {
  const options = ["CHANGE PHOTO", "REMOVE CURRENT PHOTO"];
  const [dotMenu, setDotMenu] = React.useState(null);
  const [country, setCountry] = useState("");
  const open = Boolean(dotMenu);
  const handlerDotMenu = (event) => {
    setDotMenu(event.currentTarget);
  };
  // console.log(dotMenu);
  const handleClose = () => {
    setDotMenu(null);
  };

  // ================= Time zone ====================
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [timezone, setTimezone] = useState("");
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);

  const handleFieldChange = (fieldName, fieldValue) => {
    // setFormFieldsValue({
    //     ...formFieldsValue,
    //     [fieldName]: fieldValue
    // });
  };

  useMemo(() => {
    const names = moment.tz.names();
    let offsetTmz = [];
    const uniqueOffsets = new Set();

    for (let i in names) {
      if (
        names[i].startsWith("America/") ||
        names[i].startsWith("US") ||
        names[i].startsWith("Canada")
      ) {
        let offSet = moment.tz(names[i]).format("Z");
        // console.log(offSet)
        // if (!uniqueOffsets.has(offSet)) {
        //     uniqueOffsets.add(offSet);
        //     offsetTmz = [...offsetTmz, `${names[i]} (GMT${offSet})`]
        // }
        offsetTmz = [...offsetTmz, `${names[i]} (GMT${offSet})`];
      }
    }
    console.log({ offsetTmz });
    setTimeZoneOptions(offsetTmz.map((item) => ({ label: item, value: item })));
  }, []);

  const handleChangeWithData = (fieldName, fieldData, setMethod) => {
    setMethod(fieldData);
    handleFieldChange(fieldName, fieldData);
  };
  // =================================================

  return (
    // <div className="ModalForm">
    //     <Grid container spacing={3}>

    //         <Grid item xs={12} sm={4} md={4}>
    //             <TextField
    //                 label="First Name"
    //                 required={true}
    //                 value={personalInfo?.firstName}
    //                 handleChange={(val, e) => handleChanges("firstName")(e)}
    //             />
    //         </Grid>

    //         <Grid item xs={12} sm={4} md={4}>
    //             <TextField
    //                 label="Middle Name"
    //                 required={false}
    //                 value={personalInfo?.middleName}
    //                 handleChange={(val, e) => handleChanges("middleName")(e)}
    //             />
    //         </Grid>

    //         <Grid item xs={12} sm={4} md={4}>
    //             <TextField
    //                 label="Last Name"
    //                 required={true}
    //                 value={personalInfo?.lastName}
    //                 handleChange={(val, e) => handleChanges("lastName")(e)}

    //             />
    //         </Grid>

    //         <Grid item xs={12} sm={12} md={12}>
    //             <div className="FieldLabel">Profile Photo</div>

    //             <div className="UploadPhotoCard">
    //                 <div className="UploadPhotoCardImg">
    //                     <Avatar
    //                         sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
    //                         src={personalInfo?.profilePic ? personalInfo?.profilePic : ""}
    //                     >{getProfileAvatarFromName(personalInfo)}</Avatar>
    //                 </div>

    //                 {personalInfo?.profilePic && (
    //                     <div className="UploadPhotoFiled">
    //                         <div className="UploadPhotoImgCol">
    //                             <span><img src={personalInfo?.profilePic} /></span>
    //                             <div className="ImgDetails">Profile Image</div>
    //                         </div>
    //                         <a className="UploadPhotoFiledDelete">
    //                             <DeleteForeverRoundedIcon onClick={() => {
    //                                 setPersonalInfo({ ...personalInfo, profilePic: '' });
    //                             }} />
    //                         </a>
    //                     </div>
    //                 )}

    //                 {!personalInfo?.profilePic && (
    //                     <div className="UplaodPhotoModal">
    //                         <div>

    //                             <Buttons
    //                                 label="Add From Media Library"
    //                                 buttonColor="transparent"
    //                                 border="#E4E8EC 1px solid"
    //                                 icon={<AddRoundedIcon />}
    //                                 color="#004FE0"
    //                                 width="100%"
    //                                 height="70px"
    //                                 fontSize="14px"
    //                                 link=""
    //                                 onClick={() => handleDrawerOpenSelectMedia()}
    //                             />

    //                             <SideDrawer
    //                                 title="Media Library"
    //                                 ButtonLabel="Add"
    //                                 //clickSaveButton={clickSaveButton}
    //                                 cancelButtonLabel="Cancel"
    //                                 sideOpen="right"
    //                                 open={openSelectMedia}
    //                                 handleDrawerClose={handleDrawerCloseSelectMedia}
    //                                 FooterWithoutTab={true}
    //                                 LeftButton={true}
    //                                 ButtonLeft="Upload New"
    //                                 ButtonLeftIcon={<FileUploadOutlinedIcon />}
    //                                 clickButtonLeft={handleDrawerOpenNewMedia}
    //                                 clickSaveButton={clickSelectMediaItem}
    //                                 padding="0"

    //                                 body={
    //                                     <MediaLibrarySelectMedia
    //                                         onClickRadioIcon={setSelectedValue}
    //                                         onItemSelected={getMediaItem}
    //                                     />
    //                                 }
    //                             />

    //                             <Externaluse
    //                                 open={openNewMedia}
    //                                 handleCloseButton={handleMediaUploadClose}
    //                                 handleOpenNewMedia={(handleOpenNewMedia)}
    //                             />

    //                         </div>
    //                     </div>
    //                 )}

    //             </div>

    //         </Grid>

    //         <Grid item xs={12} sm={12} md={12}>
    //             <div className="RedioButton">
    //                 <FormControl>
    //                     <FormLabel id="demo-radio-buttons-group-label" className="FieldLabel">Gender</FormLabel>
    //                     <RadioGroup
    //                         aria-labelledby="demo-radio-buttons-group-label"
    //                         defaultValue="female"
    //                         name="radio-buttons-group"
    //                         value={personalInfo?.gender}
    //                         onChange={handleChanges("gender")}
    //                     >
    //                         <FormControlLabel value="Male" control={<Radio />} label="Male" />
    //                         <FormControlLabel value="Female" control={<Radio />} label="Female" />

    //                     </RadioGroup>
    //                 </FormControl>
    //             </div>
    //         </Grid>

    //         <Grid item xs={12} sm={4} md={4}>
    //             {/* <SelectField
    //     label="Birthday"
    //     validation={false}
    //     placeholder="Month"
    //     options={getMonths()}
    //     selectedOption={personalInfo.birthMonth}
    //     handleChange={handleChanges("birthMonth")}
    //   /> */}

    //             <ComboBox
    //                 label="Birthday"
    //                 placeholder="Month"
    //                 validation={false}
    //                 options={getMonths()}
    //                 selectedOption={personalInfo?.birthMonth}
    //                 handleChange={(fieldData) => handleChangeWithData("birthMonth", fieldData)}
    //             />
    //         </Grid>

    //         <Grid item xs={12} sm={4} md={4}>
    //             <ComboBox
    //                 placeholder="Day"
    //                 validation={false}
    //                 options={dateOptions}
    //                 selectedOption={personalInfo?.birthDay}
    //                 handleChange={(fieldData) => handleChangeWithData("birthDay", fieldData)}
    //             />

    //             {/* <TextField
    //                 placeholder="Day"
    //                 value={personalInfo.birthDay}
    //                 handleChange={handleChanges("birthDay")}
    //             /> */}
    //         </Grid>

    //         <Grid item xs={12} sm={4} md={4}>
    //             {/* <SelectField
    //     placeholder="Year"
    //     value={personalInfo.birthYear}
    //     handleChange={handleChanges("birthYear")}
    //     options={getYears()}
    //     selectedOption={personalInfo.birthYear? personalInfo.birthYear : "2007"}
    //   /> */}
    //             <ComboBox
    //                 placeholder="Year"
    //                 validation={false}
    //                 options={getYears()}
    //                 selectedOption={personalInfo?.birthYear}
    //                 handleChange={(fieldData) => handleChangeWithData("birthYear", fieldData)}
    //             />
    //         </Grid>

    //         {/* country drop-down */}
    //         <Grid item xs={12} sm={12} md={12}>
    //             <ComboBox
    //                 label="Select country"
    //                 validation={true}
    //                 options={countries}
    //                 selectedOption={personalInfo?.country}
    //                 handleChange={(fieldData) => handleChangeWithData("country", fieldData)}
    //             />
    //         </Grid>

    //         <Grid item xs={12} sm={12} md={12}>
    //             <GooglePlaceField
    //                 label="Address"
    //                 validation={true}
    //                 placeholder="Your current resident address"
    //                 value={checkJsonStringOrNot(personalInfo?.address)}
    //                 handleChange={(address) => handleChangeWithData("address", address)}
    //             />

    //         </Grid>

    //         {/* height education drop-down */}
    //         {/* <Grid item xs={12} sm={12} md={12}>
    //             <ComboBox
    //                 label="Highest education"
    //                 validation={false}
    //                 options={getDataMap(highestEducation, "heduId", "title")}
    //                 // selectedOption={personalInfo.heduId}
    //                 handleChange={(fieldData) => handleChangeWithData("heduId", fieldData)}
    //             />
    //         </Grid>

    //         <Grid item xs={12} sm={12} md={12}>
    //             <TextField
    //                 label="Current profession"
    //                 required={false}
    //                 // value={personalInfo.currentProfession}
    //                 handleChange={handleChanges("currentProfession")}
    //             />
    //         </Grid> */}

    //         {/* <Grid item xs={12} sm={12} md={12}>

    // </Grid> */}

    //         {/* <Grid item xs={12} sm={12} md={12}>
    //   <ComboBox
    //       label="Current Grade"
    //       validation={false}
    //       options={getDataMap(gradeLevels, "gradeId","title")}
    //       selectedOption={personalInfo.currentGrade}
    //       handleChange={ (fieldData) => handleChangeWithData("currentGrade", fieldData)}
    //   />
    // </Grid> */}

    //         {/* <Grid item xs={12} sm={12} md={12}>
    //   <ComboBox
    //       label="Age Group"
    //       validation={false}
    //       options={getDataMap(ageGroups, "ageId","title")}
    //       selectedOption={personalInfo.ageGroup}
    //       handleChange={ (fieldData) => handleChangeWithData("ageGroup", fieldData)}
    //   />
    // </Grid> */}

    //     </Grid>
    // </div >

    <>
      {/* ========== Name ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Name<span>*</span>
        </div>
        <div
          className="FormFieldCol"
          style={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="First name"
            required={true}
            // count={20}
            name={"section"}
            // helperText={sectionErrors?.sectionName}
            // error={sectionErrors?.sectionName}
            // value={sectionObject.sectionName}
            // handleChange={(val, e) => onChangeHandler(val, e, 'sectionName')}
          />
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="Middle name"
            required={true}
            // count={20}
            name={"section"}
            // helperText={sectionErrors?.sectionName}
            // error={sectionErrors?.sectionName}
            // value={sectionObject.sectionName}
            // handleChange={(val, e) => onChangeHandler(val, e, 'sectionName')}
          />
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="Last name"
            required={true}
            // count={20}
            name={"section"}
            // helperText={sectionErrors?.sectionName}
            // error={sectionErrors?.sectionName}
            // value={sectionObject.sectionName}
            // handleChange={(val, e) => onChangeHandler(val, e, 'sectionName')}
          />
        </div>
      </div>
      {/* ========== Profile Picture ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Profile Photo<span></span>
        </div>
        <div
          className="FormFieldCol"
          style={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <div className="ProfilePhotoMainContainer">
            <Avatar>Q</Avatar>
            <div className="ProfilePhotoFileName">
              {/* <span>Upload profile photo</span> */}
              <p>filename-vicky-hladynets-C8Ta0gwPbQg-unsplash</p>
            </div>
            <div>
              {/* <Button
                variant="outlined"
                startIcon={<AddRoundedIcon />}
                sx={{
                  color: "#0450E1",
                  fontWeight: "600",
                  borderColor: "#E8EAED",
                  borderRadius: "8px",
                }}
              >
                Upload photo
              </Button> */}
              <MenuThreeDot
                options={options}
                open={open}
                state={dotMenu}
                setSate={setDotMenu}
                handleClick={handlerDotMenu}
                handleClose={handleClose}
                handlerMenu={handleClose}
              />
              {/* <IconButton>
                <MoreVertRoundedIcon />
              </IconButton> */}
            </div>
          </div>
        </div>
      </div>
      {/* ========== Gender ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Gender<span></span>
        </div>

        <div className="FormFieldCol">
          <SelectField
            label="Select gender"
            name="gender"
            options={genderOptions}
            //   selectedOption={}
            //   helperText={sectionErrors?.acededemicYear}
            //   error={sectionErrors?.acededemicYear}
            handleChange={(val) => {}}
          />
        </div>
      </div>

      {/* ========== Birthday ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Birthday<span></span>
        </div>

        <div className="FormFieldCol">
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={4}>
              <SelectField
                label="Month"
                name="month"
                options={genderOptions}
                //   selectedOption={}
                //   helperText={sectionErrors?.acededemicYear}
                //   error={sectionErrors?.acededemicYear}
                handleChange={(val) => {}}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SelectField
                label="Day"
                name="day"
                options={genderOptions}
                //   selectedOption={}
                //   helperText={sectionErrors?.acededemicYear}
                //   error={sectionErrors?.acededemicYear}
                handleChange={(val) => {}}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SelectField
                label="Year"
                name="year"
                options={genderOptions}
                //   selectedOption={}
                //   helperText={sectionErrors?.acededemicYear}
                //   error={sectionErrors?.acededemicYear}
                handleChange={(val) => {}}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      {/* ========== Highest Education ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Highest Education<span></span>
        </div>
        <div className="FormFieldCol">
          <SelectField
            label="Select highest education"
            name="highestEducation"
            options={highestEducation}
            // selectedOption={}
            // helperText={}
            // error={}
            // handleChange={(val) => onChangeHandler(val, val, 'acededemicYear')}
          />
        </div>
      </div>

      {/* ========== Highest Education ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Occupation<span></span>
        </div>
        <div className="FormFieldCol">
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="Enter occupation"
            required={true}
            // count={20}
            name={"section"}
            // helperText={sectionErrors?.sectionName}
            // error={sectionErrors?.sectionName}
            // value={sectionObject.sectionName}
            // handleChange={(val, e) => onChangeHandler(val, e, 'sectionName')}
          />
        </div>
      </div>

      {/* ========== Highest Education ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Preferred Language<span></span>
        </div>
        <div className="FormFieldCol">
          <SelectField
            label="Select preferred language"
            name="highestEducation"
            options={[{ label: "English", value: "english" }]}
            // selectedOption={}
            // helperText={}
            // error={}
            // handleChange={(val) => onChangeHandler(val, val, 'acededemicYear')}
          />
        </div>
      </div>

      {/* ========== Highest Education ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Timezone<span></span>
        </div>
        <div className="FormFieldCol">
          <SelectField
            label="Select Timezone"
            name="timezone"
            options={timeZoneOptions}
            selectedOption={timezone}
            // helperText={}
            // error={}
            // handleChange={(val) => onChangeHandler(val, val, 'acededemicYear')}
          />
        </div>
      </div>

      <Divider />

      {/* ========== Contact Information ============ */}
      <h3>Contact Information</h3>

      {/* ========== Preferred Contact ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Preferred Contact<span></span>
        </div>
        <div className="FormFieldCol">
          <SelectField
            label="Preferred Contact"
            name="preferredContact"
            options={[
              {
                label: "qadhiashraful@qafys.org (Email)",
                value: "qadhiashraful@qafys.org (Email)",
              },
            ]}
            // selectedOption={timezone}
            // helperText={}
            // error={}
            // handleChange={(val) => onChangeHandler(val, val, 'acededemicYear')}
          />
        </div>
      </div>

      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Email<span></span>
        </div>
        <div className="FormFieldCol">
          <div className="PreviewSection">qadhiashraful@qafys.org</div>
          {/* <p style={{ color: "#d32f2f" }}>Required</p> */}
        </div>
      </div>

      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Home Phone<span></span>
        </div>
        <div className="FormFieldCol">
          {/* <div className="PreviewSection">qadhiashraful@qafys.org</div> */}
          {/* <p style={{ color: "#d32f2f" }}>Required</p> */}
          <PhoneInputField
            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
            country={"us"}
            // value={phone}
            countryCodeEditable={false}
            // onChange={(value, country, e, formattedValue) => handleChangeWithData("phone", formattedValue, setPhone)}
            onBlur={() => console.log("onBlur")}
            variant="outlined"
            onlyCountries={["us", "ca", "bd"]}
            containerStyle={{}}
            buttonStyle={{}}
            inputStyle={{
              marginLeft: "0px",
              height: "40px",
              width: "100%",
              fontFamily: "Roboto-Regular",
              fontSize: "16px",
            }}
            containerClass={{}}
            errorText={"Phone number is required"}
            errorStyles={{ marginBottom: "5px" }}
            autoFormat={true}
            disableDropdown={false}
            disabled={false}
            disableCountryCode={false}
            enableSearch={false}
            disableSearchIcon={false}
            placeholder={""}
            inputProps={{}}
            specialLabel={"Primary Phone"}
            // preferredCountries={[]}
            // excludeCountries={[]}
            labelFixed="Phone Number"
            LabelStatus={false}
            //helpertext="Helper text"
            //error="error"
          />
        </div>
      </div>

      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Work Phone<span></span>
        </div>
        <div className="FormFieldCol">
          {/* <div className="PreviewSection">qadhiashraful@qafys.org</div> */}
          {/* <p style={{ color: "#d32f2f" }}>Required</p> */}
          <PhoneInputField
            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
            country={"us"}
            // value={phone}
            countryCodeEditable={false}
            // onChange={(value, country, e, formattedValue) => handleChangeWithData("phone", formattedValue, setPhone)}
            onBlur={() => console.log("onBlur")}
            variant="outlined"
            onlyCountries={["us", "ca", "bd"]}
            containerStyle={{}}
            buttonStyle={{}}
            inputStyle={{
              marginLeft: "0px",
              height: "40px",
              width: "100%",
              fontFamily: "Roboto-Regular",
              fontSize: "16px",
            }}
            containerClass={{}}
            errorText={"Phone number is required"}
            errorStyles={{ marginBottom: "5px" }}
            autoFormat={true}
            disableDropdown={false}
            disabled={false}
            disableCountryCode={false}
            enableSearch={false}
            disableSearchIcon={false}
            placeholder={""}
            inputProps={{}}
            specialLabel={"Primary Phone"}
            // preferredCountries={[]}
            // excludeCountries={[]}
            labelFixed="Phone Number"
            LabelStatus={false}
            //helpertext="Helper text"
            //error="error"
          />
        </div>
      </div>

      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Cell/Mobile Phone<span></span>
        </div>
        <div className="FormFieldCol">
          {/* <div className="PreviewSection">qadhiashraful@qafys.org</div> */}
          {/* <p style={{ color: "#d32f2f" }}>Required</p> */}
          <PhoneInputField
            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
            country={"us"}
            // value={phone}
            countryCodeEditable={false}
            // onChange={(value, country, e, formattedValue) => handleChangeWithData("phone", formattedValue, setPhone)}
            onBlur={() => console.log("onBlur")}
            variant="outlined"
            onlyCountries={["us", "ca", "bd"]}
            containerStyle={{}}
            buttonStyle={{}}
            inputStyle={{
              marginLeft: "0px",
              height: "40px",
              width: "100%",
              fontFamily: "Roboto-Regular",
              fontSize: "16px",
            }}
            containerClass={{}}
            errorText={"Phone number is required"}
            errorStyles={{ marginBottom: "5px" }}
            autoFormat={true}
            disableDropdown={false}
            disabled={false}
            disableCountryCode={false}
            enableSearch={false}
            disableSearchIcon={false}
            placeholder={""}
            inputProps={{}}
            specialLabel={"Primary Phone"}
            // preferredCountries={[]}
            // excludeCountries={[]}
            labelFixed="Phone Number"
            LabelStatus={false}
            //helpertext="Helper text"
            //error="error"
          />
        </div>
      </div>

      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Country<span></span>
        </div>
        <div className="FormFieldCol">
          <ComboBox
            label=""
            validation={false}
            options={countries}
            selectedOption={country}
            // handleChange={(val, e) => handleChanges(val, e, setCountry, 'country')}
            // onBlur={(e) => checkRequiredNumber(e, 'country')}
            // error={(countryError != "")}
            // helperText={(countryError != "") ? countryError : ''}
          />
        </div>
      </div>

      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Address<span></span>
        </div>
        <div className="FormFieldCol">
          <GooglePlaceField
            label=""
            placeholder="Search your address"
            // value={checkJsonStringOrNot(address)}
            // handleChange={(address) => handleChangeWithData("address", address, setAddress)}
          />
        </div>
      </div>
    </>
  );
}

export default UpdatePersonalInfo;

const genderOptions = [
  {
    label: "Male",
    value: "male",
  },

  {
    label: "Female",
    value: "female",
  },

  {
    label: "Others",
    value: "others",
  },
];

const highestEducation = [
  {
    label: "None",
    value: "None",
  },

  {
    label: "Less than high school diploma (or equivalent)",
    value: "Less than high school diploma (or equivalent)",
  },

  {
    label: "High school diploma (or equivalent)",
    value: "High school diploma (or equivalent)",
  },

  {
    label: "Some college but no degree",
    value: "Some college but no degree",
  },

  {
    label: "Associate Degree (e.g., AA, AS)",
    value: "Associate Degree (e.g., AA, AS)",
  },

  {
    label: "Bachelor's degree (e.g., BA, AB, BS)",
    value: "Bachelor's degree (e.g., BA, AB, BS)",
  },

  {
    label: "Master's degree (e.g., MA, MS, MEng, MEd, MSW, MBA)",
    value: "Master's degree (e.g., MA, MS, MEng, MEd, MSW, MBA)",
  },

  {
    label: "Professional school degree (e.g., MD, DDS, DVM, LLB, JD0",
    value: "Professional school degree (e.g., MD, DDS, DVM, LLB, JD0",
  },

  {
    label: "Doctorate degree (e.g., PhD, EdD)",
    value: "Doctorate degree (e.g., PhD, EdD)",
  },
];

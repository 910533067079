import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormHelperText, OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import './SelectField.css';

function SelectField(props) {
  // const [age, setAge] = React.useState('');
  // const handleChanges = (event) => {
  //   setAge(event.target.value);
  // };

  // const options = [
  //   {
  //     label: "Twenty",
  //     value: "Twenty",
  //   },

  //   {
  //     label: "Twentydd",
  //     value: "Twentyd",
  //   },

  // ]

  //   < SelectField
  // label = "Month"
  //FieldLabel={true}
  // options = { options }
  // handleChange = { handleChanges }
  //   />

  const {
    label,
    validation,
    required,
    options,
    selectedOption,
    handleChange,
    background,
    error,
    helperText,
    name,
    isDisabled,
    placeholder,
    errorBorderColor
  } = props;

  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState('');
  const [selectedOpt, setSelectedOpt] = useState("")

  useEffect(() => {
    if (required || validation) {
      if (!selectedOption) {
        setValidationError(true);
        setValidationText('Required');
      } else {
        setValidationError(false);
        setValidationText('');
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (required || validation) {
      if (selectedOption === undefined || selectedOption === "") {
        setValidationError(true);
        setValidationText('Required');
      } else {
        setValidationError(false);
        setValidationText('');
      }
    }
  }, [selectedOption]);

  // console.log("selectField", selectedOption)

  return (
    <div className="SelectFiled">
      {props.FieldLabel && (
        <div className="FieldLabel">
          {label} {(validation || required) && <span>*</span>}
        </div>
      )}
      <FormControl error={error || validationError}>
        <InputLabel className='SelectFieldPlaceHolder'>{props.label}</InputLabel>
        <Select
          labelId="customized-select-label"
          id="customized-select"
          value={selectedOption}
          onChange={handleChange}
          error={error || validationError}
          name={name}
          disabled={isDisabled}
          style={{ background: `${background}`, border: `${error && `1px solid #ba1b1a`}`, borderRadius: '0px' }}
        >
          {/* <MenuItem disabled value="">
            {placeholder || 'Select'}
          </MenuItem> */}
          {options?.map((item, i) => (
            <MenuItem key={item.value} value={item.value} style={{ display: "block", padding: "8px 15px" }}>
              {item.label}
            </MenuItem>
          ))}
          <div onClick={props.onClickAddNew}>
            {props.AddNew &&
              <div className='SelectFieldAddNew' onClick={props.onClickAddNew}><AddRoundedIcon /> Add New</div>
            }
          </div>

        </Select>
        {(helperText || validationText) && (
          <FormHelperText >{helperText || validationText}</FormHelperText>
        )}
      </FormControl>



    </div>
  );
}

export default SelectField;

import * as React from 'react';
import './InnerPageLayout.css';
import Button from '@mui/material/Button';
import { emphasize, withStyles } from '@material-ui/core/styles';
import BreadcrumbsComp from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Buttons from '../Form/Button/Button';

import SideBarTabs from './SideBarTabs';





const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      cursor: 'pointer',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);



function InnerPageLayout(props) {
  const { BackArrow, BackArrowOnlcikc, BackArrowlink, breadcrumbsData, BreadcrumbsStatus, menuOptions, MenuStatus,
    ButtonFilledStatus, ButtonFilledIcon, ButtonFilledLink, ButtonFilledOnClick, ButtonFilledLabel, ButtonOutLinedStatus,
    ButtonOutLinedLabel, ButtonOutLinedIcon, ButtonOutLinedLink, ButtonOutLinedOnClick, CloseArrow, SideBarStatus, BodySpace,
    sideBarTabLabel, sideBarTabBody, BodyStatus, Body

  } = props;



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <div className='Inner-Page-Container'>

      {/* ==Header Start ==*/}
      <div className='Inner-Page-Header'>

        {/* ==Header Left Col Start ==*/}
        <div className='Inner-Page-Header-Left-Col'>
          {BackArrow &&
            <Button href={BackArrowlink} onClick={BackArrowOnlcikc} className='Inner-Page-Header-Back-Arrow'><KeyboardArrowLeftIcon /></Button>
          }

          {BreadcrumbsStatus &&
            <div className="Bread-Crumbs">
              <BreadcrumbsComp aria-label="breadcrumb">
                {breadcrumbsData.map((item, i) => (
                  <StyledBreadcrumb
                    component="a"
                    href={item.link}
                    label={item.label}
                    icon={item.icon}
                    onClick={item.handleClick}
                    className='Bread-Crumbs-Link'
                  />
                ))}

              </BreadcrumbsComp>
            </div>
          }

        </div>
        {/* ==Header Left Col End ==*/}


        {/* ==Header Right Col Start ==*/}
        <div className='Inner-Page-Header-Right-Col'>
          <div className='Inner-Page-Header-Buttons'>
            {ButtonFilledStatus &&
              <Buttons
                label={ButtonFilledLabel}
                icon={ButtonFilledIcon}
                buttonColor="#0450E1"
                border="#0450E1 1px solid"
                color="#fff"
                width=""
                height="32px"
                fontSize="14px"
                link={ButtonFilledLink}
                onClick={ButtonFilledOnClick}
              />
            }

            {ButtonOutLinedStatus &&
              <Buttons
                label={ButtonOutLinedLabel}
                icon={ButtonOutLinedIcon}
                border="#E8EAED 1px solid"
                color="#0450E1"
                width=""
                height="32px"
                fontSize="14px"
                link={ButtonOutLinedLink}
                onClick={ButtonOutLinedOnClick}
              />
            }
          </div>

          {MenuStatus &&
            <div className='Inner-Page-Header-Menu'>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {menuOptions.map((item) => (
                  <MenuItem onClick={item.onClick}>
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          }


          {CloseArrow &&
            <Button href={BackArrowlink} onClick={BackArrowOnlcikc} className='Inner-Page-Header-Back-Arrow'><CloseIcon /></Button>
          }

        </div>
        {/* ==Header Right Col End ==*/}

      </div>

      {/* ==Header End ==*/}

      {/* ==Body Start ==*/}

      <div className='Inner-Page-body'>

        {SideBarStatus &&
          <SideBarTabs
            sideBarTabLabel={sideBarTabLabel}
            sideBarTabBody={sideBarTabBody}
          />
        }

        {BodyStatus &&
          <div className='Inner-Page-Content'>
            {Body}
          </div>
        }

      </div>

      {/* ==Body End ==*/}


    </div>
  );
}


export default InnerPageLayout;
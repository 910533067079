import React, { useState, useEffect } from "react";

import "./SideBarTabs.css";

import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import StickyBox from "react-sticky-box";

function SideBarTabs(props) {
  const { sideBarTabLabel, sideBarTabBody } = props;

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="Inner-Page-Side-Bar-Tabs">
      <div className="Inner-Page-Side-Bar">
        <div className="Inner-Page-Side-Bar-body">
          <div className="TabListingContainer">
            <StickyBox offsetTop={120} offsetBottom={20}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                {sideBarTabLabel.map((item, i) => (
                  <Tab
                    label={item.label}
                    icon={item.icon}
                    {...a11yProps(0)}
                    disabled={item.disabled}
                  />
                ))}
              </Tabs>
            </StickyBox>
          </div>
        </div>
      </div>

      <div className="Inner-Page-Side-Bar-Pannel">
        {sideBarTabBody.map((item, i) => (
          <TabPanel value={value} index={item.id}>
            {props.alertBox}
            {item.panel}
          </TabPanel>
        ))}
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default SideBarTabs;

import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
  getUserAuthData,
  getUserData,
} from "../components/hooks/HelperFunctions";
import { useSelector } from "react-redux";

const ParentRouteWithoutLayout = ({ collapsed, children }) => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  // const userAuthenticated = getUserAuthData();
  // retrive user data
  // const userData = getUserData();
  // assign user role
  const userType = user?.userType ? user.userType : "";
  if (!user) {
    return (
      <Navigate to="/signin" state={{ from: location.pathname }} replace />
    );
  } else if (!(userType === "parent")) {
    return (
      <Navigate
        to="/school-portal"
        state={{ from: location.pathname }}
        replace
      />
    );
  }

  return <>{children}</>;
};

export default ParentRouteWithoutLayout;

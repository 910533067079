import React, { useEffect, useState } from "react";
import "../../../assets/css/users.css";

import DraggableDialog from "../../../components/hooks/Dialog";
import { useHistory, useNavigate, useParams } from "react-router-dom";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import {
  useGetCourseDetailsQuery,
  useDeleteCourseMutation,
  useGetClassLevelListQuery,
} from "../../../redux/features/classes/classApi";
import { toast } from "react-toastify";
import useProtected from "../../../hooks/useProtected";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import { useGetSchoolYearListQuery } from "../../../redux/features/school_years/schoolYearApi";
import { TbUsers } from "react-icons/tb";
import { RiTeamLine } from "react-icons/ri";

import InnerPageLayout from "../../../components/InnerPageLayout/InnerPageLayout";
import { RxPerson } from "react-icons/rx";
import { BsPersonCheck } from "react-icons/bs";
import { RiCalendar2Line } from "react-icons/ri";
import { LuFileBarChart2 } from "react-icons/lu";
import { RiBankCardLine } from "react-icons/ri";
import { RiParentFill } from "react-icons/ri";
import { RiContactsBookLine } from "react-icons/ri";

import Overview from "./StudentsDetailsOverview/Overview";
import StudentAttendance from "./StudentAttendance";
import GuardianParents from "./GuardianParents";
import { useGetSingleStudentDetailsQuery } from "../../../redux/features/parentPortal/students/studentsApi";
import EmergencyContact from "./EmergencyContact";

const StudentsDetails = () => {
  //============ data fetching start ========================//
  const { id } = useParams();
  const [studentDetails, setStudentsDetails] = useState([]);
  const {
    data,
    isLoading,
    isError,
    error: studentDetailsError,
  } = useGetSingleStudentDetailsQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  useProtected(studentDetailsError);
  useEffect(() => {
    if (data && !isError && !isLoading) {
      setStudentsDetails(data?.body?.data);
    }
  }, [data, isLoading, isError]);
  //============ data fetching end ========================//

  const breadcrumbsData = [
    {
      label: "Students",
      link: "/students",
      icon: (
        <span style={{ margin: "0 0 -4px 4px", fontSize: "18px" }}>
          <RxPerson />
        </span>
      ),
    },

    {
      label: `${studentDetails?.firstName} ${studentDetails?.lastName}`,
    },
  ];

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sideBarTabLabel = [
    {
      icon: <RxPerson />,
      label: "Overview",
    },
    {
      icon: <BsPersonCheck />,
      label: "Attendance",
    },
    // {
    //   icon: <RiCalendar2Line />,
    //   label: "Schedules",
    //   disabled: true,
    // },
    // {
    //   icon: <LuFileBarChart2 />,
    //   label: "Report Cards",
    //   disabled: true,
    // },
    // {
    //   icon: <RiBankCardLine />,
    //   label: "Payments",
    //   disabled: true,
    // },
    {
      icon: <RiParentFill />,
      label: "Guardian/Parents",
    },
    {
      icon: <RiContactsBookLine />,
      label: "Emergency Contacts",
    },
  ];

  const sideBarTabBody = [
    {
      id: 0,
      panel: <Overview studentDetails={studentDetails} />,
    },
    {
      id: 1,
      panel: <StudentAttendance />,
    },
    // {
    //   id: 2,
    //   panel: <>tab three</>,
    // },
    // {
    //   id: 3,
    //   panel: <>tab four</>,
    // },
    // {
    //   id: 4,
    //   panel: <>tab five</>,
    // },
    {
      id: 2,
      panel: <GuardianParents studentDetails={studentDetails} />,
    },

    {
      id: 3,
      panel: <EmergencyContact studentDetails={studentDetails} />,
    },
  ];

  return (
    <div className="studentDetailsParentContainer">
      <InnerPageLayout
        BackArrow={true}
        BackArrowlink={`/students`}
        //BackArrowOnlcikc={BackArrowOnlcikc}
        CloseArrow={true}
        BreadcrumbsStatus={true}
        breadcrumbsData={breadcrumbsData}
        SideBarStatus={true}
        sideBarTabLabel={sideBarTabLabel}
        sideBarTabBody={sideBarTabBody}
      />
    </div>
  );
};

export default StudentsDetails;

import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// const options = ["None", "Atria", "Callisto", "Dione"];

const ITEM_HEIGHT = 48;

export default function MenuThreeDot(props) {
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={props.open ? "long-menu" : undefined}
        aria-expanded={props.open ? "true" : undefined}
        aria-haspopup="true"
        onClick={props.handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={props.state}
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            // width: "20ch",
          },
        }}
      >
        {props.options.map((option) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={props.handlerMenu}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

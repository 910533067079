import React, { useEffect, useState } from "react";
import { Avatar, Box } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Status from "../../../components/Status/Status";
import About from "./GuardianParentsDetailsSteps/About";
import Contact from "./GuardianParentsDetailsSteps/Contact";
import { useGetSingleParentDetailsQuery } from "../../../redux/features/parentPortal/students/studentsApi";
import { getProfileAvatarFromName } from "../../../utils/helpers";
import useProtected from "../../../hooks/useProtected";
import { capitalizeWords } from "../../../hooks/HelperFunctions";

const GuardianParentsDetails = ({ showId }) => {
  const { data, isLoading, error } = useGetSingleParentDetailsQuery(showId, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  useProtected(error);
  const [value, setValue] = React.useState(0);

  const [guardianParentsDetails, setGuardianParentsDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    status: "",
    preferredContact: "",
    homePhone: "",
    workPhone: "",
    address: "",
    occupation: "",
    preferredLanguage: "",
    highestEducation: "",
  });

  useEffect(() => {
    if (data && !isLoading && !error) {
      setGuardianParentsDetails({
        ...guardianParentsDetails,
        firstName: data?.body?.data?.firstName,
        lastName: data?.body?.data?.lastName,
        email: data?.body?.data?.email,
        status: data?.body?.data?.status,
        preferredContact: data?.body?.data?.preferredContact,
        homePhone: data?.body?.data?.homePhone,
        workPhone: data?.body?.data?.workPhone,
        address: data?.body?.data?.address
          ? data?.body?.data?.address?.label
          : "",
        occupation: data?.body?.data?.occupation,
        preferredLanguage: data?.body?.data?.preferredLanguage,
        highestEducation: data?.body?.data?.highestEducation,
      });
    }
  }, [data, isLoading, error]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // console.log(guardianParentsDetails, 328);
  const staticStatustData = [
    {
      Label: "Status",
      SubLabel: capitalizeWords(guardianParentsDetails?.status) || "N/A",
      Color: "#41AB37",
      line: true,
    },

    {
      Label: "Preferred Contact",
      SubLabel: guardianParentsDetails?.preferredContact || "N/A",
      Color: "#7D8597",
    },
  ];

  return (
    <div>
      <div className="guardianParentDetailsContainer">
        <div className="guardianImageContainer">
          {/* <Avatar>P</Avatar> */}
          <Avatar
            sx={{ width: 106, height: 106 }}
            alt={guardianParentsDetails?.firstName}
            src={
              guardianParentsDetails?.idPicture
                ? guardianParentsDetails?.idPicture
                : getProfileAvatarFromName(guardianParentsDetails?.firstName)
            }
          ></Avatar>
        </div>
        <div className="guardianInfoContainer">
          <h2>
            {guardianParentsDetails?.firstName}{" "}
            {guardianParentsDetails?.lastName}
            <FiberManualRecordIcon
              sx={{ fontSize: "12px", color: "#66BD50" }}
            />
          </h2>
          <div className="P_imgSectionInfoSub">
            <p>
              ID:{" "}
              {guardianParentsDetails?.userUniqueID || (
                <span style={{ color: "#BBBFC9" }}>No data available</span>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="guardianParentStatusContainer">
        <Status
          MainTitle={true}
          Title={``}
          BackLinkLabel=""
          Backlink="#"
          StaticStatus={true}
          StatusLine={false}
          BottomLine={false}
          staticStatustData={staticStatustData}
          guardianParentsDetails={guardianParentsDetails}
        />
      </div>

      <div className="H_Tab_c">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="About" {...a11yProps(0)} sx={{ width: "50%" }} />
            <Tab label="Contact" {...a11yProps(1)} sx={{ width: "50%" }} />
          </Tabs>
        </Box>

        <div className="TsbPanelContainer" style={{ padding: "0" }}>
          <CustomTabPanel value={value} index={0}>
            <About guardianParentsDetails={guardianParentsDetails} />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Contact guardianParentsDetails={guardianParentsDetails} />
          </CustomTabPanel>
        </div>
      </div>
    </div>
  );
};

export default GuardianParentsDetails;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Buttons from '../../components/Form/Button/Button';
import TextField from '../../components/Form/TextField/TextField';
import PasswordStrength from '../../components/Form/FieldPassword/PasswordStrength';
import FieldPassword from '../../components/Form/FieldPassword/FieldPassword';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import AlrtMessage from '../../components/Form/AlrtMessage/AlertMessage';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosApi from '../../utils/axios';
import { calculatePasswordStrength, validateFormData } from '../../utils/form';
import LogoHeader from './LogoHeader/LogoHeader';
import { toast } from 'react-toastify';
// import AlrtMessage from '../../../components/AlrtMessage/AlrtMessage';

// import styles from '../../../assets/css/form.module.css';

function ResetPassword(props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [, setPasswordValidation] = useState(true);
  const [otp, setOtp] = useState('');
  const [userId, setUserId] = useState('');
  const [alertOptions, setAlertOptions] = useState({
    msg: '',
    type: 'error',
  });
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });
  const [confirmPasswordError, setConfirmPasswordError] = useState('')


  //================== google recapcha v3 part start ====================//
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  //================== google recapcha v3 part end ====================//

  useEffect(() => {
    const otp = searchParams.get('otp');
    if (otp) {
      setOtp(otp);
    }
    const userId = searchParams.get('userId');
    if (userId) {
      setUserId(userId);
    }
  }, [searchParams]);
  const passwordMatched = () => {
    if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords don't match")
      return false
    }
    return true
  }
  const handleCreateNewPassword = async () => {

    ///===================== recapcha ==============================///
    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha.execute(`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, { action: 'submit' });
        ///===================== recapcha ==============================///

        const data = {
          otp,
          userId,
          password,
          token
        };

        const isValid = validateFormData({ password, confirmPassword }, setErrors, {
          password: 'Enter new password',
          confirmPassword: 'Confirm new password',
        });

        if (!isValid || !passwordMatched()) {
          return;
        }
        setConfirmPasswordError('')

        const strength = calculatePasswordStrength(password);
        if (strength < 4) {
          let err = { ...errors };
          err['password'] = 'Please enter a strong password';
          setErrors(err);
          return;
        }

        if (!(password === confirmPassword)) {
          let err = { ...errors };
          err['confirmPassword'] = "Password doesn't match";
          setErrors(err);
          return;
        }

        try {
          const result = await axiosApi.post(
            '/users/public/forgot-password-verify',
            data,
          );
          if (result.data.error) {
            setAlertOptions({
              msg: result.data.message,
              type: 'error',
            });
            return;
          }

          toast.success('Password changed successfully. Redirecting you now...')
          setTimeout(() => {
            navigate(`/signin`);
          }, 2000);
        } catch (err) {
          setAlertOptions({
            msg: err.message,
            type: 'error',
          });
        }

      } catch (error) {
        console.error('Error:', error);
      }
    });

  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOptions({
      msg: '',
      type: '',
    });
  };
  useEffect(() => {
    console.log(confirmPasswordError)
  }, [confirmPasswordError])

  return (
    <>
      <div className="SignContainer">
        <LogoHeader />
        <div className="SignContainerInner">
          <div className="SignForm">
            <h1>Create new password</h1>
            <AlrtMessage
              open={Boolean(alertOptions.msg)}
              message={alertOptions.msg}
              severity={alertOptions.type}
              closeHandler={handleAlertClose}
            />
            <Grid container spacing={3}>

              <Grid item xs={12} md={12}>
                <PasswordStrength
                  // handlePasswordText={setPassword}
                  handlePasswordValidation={setPasswordValidation}
                  placeholder="New password"
                  required={true}
                  handlePasswordText={(value) => {
                    setErrors((prev) => {
                      prev.password = '';
                      return prev;
                    });
                    setPassword(value);
                  }}
                  helperText={errors?.password}
                  error={errors?.password}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FieldPassword
                  // handlePasswordText={setConfirmPassword}
                  handlePasswordValidation={setPasswordValidation}
                  placeholder="Confirm new password"
                  required={true}
                  handleChange={(value) => {
                    setErrors((prev) => {
                      prev.confirmPassword = '';
                      return prev;
                    });
                    setConfirmPassword(value);
                  }}
                  helperText={confirmPasswordError}
                  error={confirmPasswordError}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Buttons
                  label="Reset Password"
                  buttonColor="#FFB606"
                  border="#FFB606 1px solid"
                  color="#000000"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  // link="/signin"
                  onClick={handleCreateNewPassword}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <div className="RedendButtom">
                  <Buttons
                    label="Cancel"
                    buttonColor="#FFF"
                    border="#ccc 0px solid"
                    color="#004FE0"
                    width=""
                    height="48px"
                    fontSize="14px"
                    //disabled={resendOtpButton}
                    link="/signin"
                    onClick={undefined}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="SignFormFooter">
          <SecurityOutlinedIcon />
          <span>SECURE SSL ENCRYPTION</span>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;

import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { Grid } from "@mui/material";
import Buttons from "../../../../components/Form/Button/Button";
import moment from "moment-timezone";
import { countries } from "../../../../assets/data/countries";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    color: "#5C677D",
    minWidth: "250px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "1px solid #e8eaed",
    // "&:nth-of-type(even)": {
    //   backgroundColor: "#FCFCFC",
    // },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

const OverviewTables = ({ studentDetails }) => {
  //============= copy part start ===================================//
  const handleCopyCode = (data) => {
    if (data === undefined) {
      navigator.clipboard.writeText("No data");
    } else {
      navigator.clipboard.writeText(data);
    }
  };
  //============= copy part start ===================================//

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item sm={12} md={12} lg={6}>
          {/* ====================== Access Details Table ======================= */}
          <div
            className="TableContainer oddEvenTable"
            style={{ overflow: "hidden" }}
          >
            <div
              className="TableInnerHeader"
              style={{
                border: "1px solid #e8eaed",
                borderBottom: "0px",
                padding: "12px",
                color: "#5C677D",
                textTransform: "uppercase",
              }}
            >
              <h3>Access details</h3>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      Name
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      {/* <div
                        className="cellwithchip"
                        style={{
                          color: "#0B132B",
                          fontWeight: "600",
                          margin: "0px",
                        }}
                      > */}
                      {/* <div className="VerifyChip">
                      <VerifiedRoundedIcon /> Verified
                    </div> */}
                      {/* </div> */}
                      {studentDetails?.firstName} {studentDetails?.lastName}{" "}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Gender</StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{ color: "#0B132B", fontWeight: "600" }}
                    >
                      {studentDetails?.gender
                        ? studentDetails?.gender
                        : "No Data"}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Birthday</StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{ color: "#0B132B", fontWeight: "600" }}
                    >
                      {" "}
                      {studentDetails?.birthDate
                        ? `**/**/${moment(studentDetails.birthDate).format(
                            "YYYY"
                          )}`
                        : "No Data"}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <br />
          {/* ====================== Contact Table ======================= */}
          <div
            className="TableContainer oddEvenTable"
            style={{ overflow: "hidden" }}
          >
            <div
              className="TableInnerHeader"
              style={{
                border: "1px solid #e8eaed",
                borderBottom: "0px",
                padding: "12px",
                color: "#5C677D",
                textTransform: "uppercase",
              }}
            >
              <h3>Contact</h3>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      Email
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div
                        className="cellwithchip"
                        style={{ color: "#0B132B", fontWeight: "600" }}
                      >
                        {studentDetails?.email
                          ? studentDetails?.email
                          : "No Data"}
                        <VerifiedRoundedIcon
                          fontSize="small"
                          sx={{ color: "#0450E1" }}
                        />
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Phone number</StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{ color: "#0B132B", fontWeight: "600" }}
                    >
                      {studentDetails?.phone
                        ? studentDetails?.phone
                        : "No Data"}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Country</StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{ color: "#0B132B", fontWeight: "600" }}
                    >
                      {studentDetails?.country
                        ? countries?.find(
                            (country) =>
                              country.value === studentDetails.country
                          )?.label
                        : "No Data"}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Address</StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{ color: "#0B132B", fontWeight: "600" }}
                    >
                      {studentDetails?.address?.label
                        ? studentDetails?.address?.label
                        : "No Data"}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          {/* ====================== Academics Table ======================= */}
          <div
            className="TableContainer oddEvenTable"
            style={{ overflow: "hidden" }}
          >
            <div
              className="TableInnerHeader"
              style={{
                border: "1px solid #e8eaed",
                borderBottom: "0px",
                padding: "12px",
                color: "#5C677D",
                textTransform: "uppercase",
              }}
            >
              <h3>Academics</h3>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      School
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div
                        className="cellwithchip"
                        style={{ color: "#0B132B", fontWeight: "600" }}
                      >
                        {" "}
                        {studentDetails?.schoolName
                          ? studentDetails?.schoolName
                          : "No Data"}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Student ID</StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className="TableInfo accessDetailsPasswordRow"
                      style={{ color: "#0B132B", fontWeight: "600" }}
                    >
                      {studentDetails?.userUniqueID
                        ? studentDetails?.userUniqueID
                        : "No Data"}
                      <div style={{ paddingRight: "8px" }}>
                        <Buttons
                          label="Copy"
                          icon={<ContentCopyOutlinedIcon fontSize="small" />}
                          buttonColor=""
                          border="#C6C6D0 1px solid"
                          color="#004FE0"
                          width=""
                          height="28px"
                          fontSize="14px"
                          onClick={(e) =>
                            handleCopyCode(studentDetails?.userUniqueID)
                          }
                        />
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">NYSED ID #</StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className="TableInfo accessDetailsPasswordRow"
                      style={{ color: "#0B132B", fontWeight: "600" }}
                    >
                      {studentDetails?.nysedId
                        ? studentDetails?.nysedId
                        : "No Data"}
                      <div style={{ paddingRight: "8px" }}>
                        <Buttons
                          label="Copy"
                          icon={<ContentCopyOutlinedIcon fontSize="small" />}
                          buttonColor=""
                          border="#C6C6D0 1px solid"
                          color="#004FE0"
                          width=""
                          height="28px"
                          fontSize="14px"
                          onClick={(e) =>
                            handleCopyCode(studentDetails?.nysedId)
                          }
                        />
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Current class/level
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{ color: "#0B132B", fontWeight: "600" }}
                    >
                      {" "}
                      {studentDetails?.currentClassLevel
                        ? studentDetails?.currentClassLevel
                        : "No Data"}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Joined date</StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{ color: "#0B132B", fontWeight: "600" }}
                    >
                      {studentDetails?.createdAt
                        ? moment(studentDetails?.createdAt).format(
                            "DD-MMM-YYYY"
                          )
                        : "No Data"}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OverviewTables;

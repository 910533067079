import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { Button, Grid } from "@mui/material";
import Buttons from "../../../../components/Form/Button/Button";
import moment from "moment-timezone";
import { countries } from "../../../../assets/data/countries";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    color: "#5C677D",
    minWidth: "300px",
  },
  ".MuiTableCell-root": {
    minWidth: "200px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "1px solid #e8eaed",
    // "&:nth-of-type(even)": {
    //   backgroundColor: "#FCFCFC",
    // },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

const Profile = ({ personalInfo, EditDrawerOpen }) => {
  //============= copy part start ===================================//
  const handleCopyCode = (data) => {
    if (data === undefined) {
      navigator.clipboard.writeText("No data");
    } else {
      navigator.clipboard.writeText(data);
    }
  };
  //============= copy part start ===================================//

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item sm={12} md={12} lg={12}>
          {/* ====================== Access Details Table ======================= */}
          <div
            className="TableContainer oddEvenTable"
            style={{ overflow: "hidden" }}
          >
            <div
              className="TableInnerHeader"
              style={{
                border: "1px solid #e8eaed",
                borderBottom: "0px",
                padding: "12px",
                color: "#5C677D",
              }}
            >
              <h3>PERSONAL DETAILS</h3>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      ID
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="cellwithchip">
                        {personalInfo?.userUniqueID || "N/A"}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      Name
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="cellwithchip">
                        {personalInfo?.firstName || "N/A"}{" "}
                        {personalInfo?.lastName || "N/A"}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Gender</StyledTableCell>
                    <StyledTableCell align="left">
                      {/* <div className="cellwithchip">Male</div> */}
                      {personalInfo?.gender ? (
                        personalInfo?.gender
                      ) : (
                        <Button
                          variant="text"
                          onClick={EditDrawerOpen}
                          sx={{
                            p: 0,
                            m: 0,
                            color: "#0450E1",
                            fontWeight: "600",
                            textTransform: "initial",
                          }}
                        >
                          Add gender
                        </Button>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Birthday</StyledTableCell>
                    <StyledTableCell align="left">
                      {" "}
                      {personalInfo?.birthDate
                        ? `**/**/${moment(personalInfo.birthDate).format(
                            "YYYY"
                          )}`
                        : "No Data"}
                      {/* 
                        <Button
                        variant="text"
                        sx={{
                          p: 0,
                          m: 0,
                          color: "#0450E1",
                          fontWeight: "600",
                          textTransform: "initial",
                        }}
                      >
                        Add Birthday
                      </Button>
                         */}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Highest Education
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {personalInfo?.highestEducation || "N/A"}
                      {/* 
                        <Button
                        variant="text"
                        sx={{
                          p: 0,
                          m: 0,
                          color: "#0450E1",
                          fontWeight: "600",
                          textTransform: "initial",
                        }}
                      >
                        Add Birthday
                      </Button>
                         */}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Occupation</StyledTableCell>
                    <StyledTableCell align="left">
                      {/* Occupation */}
                      {personalInfo?.occupation || (
                        <Button
                          variant="text"
                          onClick={EditDrawerOpen}
                          sx={{
                            p: 0,
                            m: 0,
                            color: "#0450E1",
                            fontWeight: "600",
                            textTransform: "initial",
                          }}
                        >
                          Add occupation
                        </Button>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Preferred Language
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {personalInfo?.preferredLanguage || "N/A"}
                      {/* <Button
                        variant="text"
                        sx={{
                          p: 0,
                          m: 0,
                          color: "#0450E1",
                          fontWeight: "600",
                          textTransform: "initial",
                        }}
                      >
                        Add occupation
                      </Button> */}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Timezone</StyledTableCell>
                    <StyledTableCell align="left">
                      {personalInfo?.timezone || "N/A"}
                      {/* <Button
                        variant="text"
                        sx={{
                          p: 0,
                          m: 0,
                          color: "#0450E1",
                          fontWeight: "600",
                          textTransform: "initial",
                        }}
                      >
                        Add occupation
                      </Button> */}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <br />
          {/* ====================== Contact Table ======================= */}
          <div
            className="TableContainer oddEvenTable"
            style={{ overflow: "hidden" }}
          >
            <div
              className="TableInnerHeader"
              style={{
                border: "1px solid #e8eaed",
                borderBottom: "0px",
                padding: "12px",
                color: "#5C677D",
              }}
            >
              <h3>CONTACT</h3>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      Preferred Contact
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="cellwithchip">
                        {personalInfo?.preferredContact || "N/A"}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      Email
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="cellwithchip">
                        {personalInfo?.email || "N/A"} &nbsp;
                        <VerifiedRoundedIcon
                          fontSize="small"
                          sx={{ color: "#0450E1" }}
                        />
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Home Phone</StyledTableCell>
                    <StyledTableCell align="left">
                      {personalInfo?.homePhone || "N/A"}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Work Phone</StyledTableCell>
                    <StyledTableCell align="left">
                      {personalInfo?.workPhone || (
                        <Button
                          variant="text"
                          onClick={EditDrawerOpen}
                          sx={{
                            p: 0,
                            m: 0,
                            color: "#0450E1",
                            fontWeight: "600",
                            textTransform: "initial",
                          }}
                        >
                          Add phone
                        </Button>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Cell/Mobile Phone
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {personalInfo?.cellPhone || "N/A"}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Country</StyledTableCell>
                    <StyledTableCell align="left">
                      {personalInfo?.country || "N/A"}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left">Address</StyledTableCell>
                    <StyledTableCell align="left">
                      {personalInfo?.address || "N/A"}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Profile;

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { capitalizeWords } from "../../../../hooks/HelperFunctions";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    color: "#5C677D",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "1px solid #e8eaed",
    // "&:nth-of-type(even)": {
    //   backgroundColor: "#FCFCFC",
    // },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

const About = ({ guardianParentsDetails }) => {
  return (
    <div>
      <div
        className="TableContainer oddEvenTable"
        style={{ overflow: "hidden" }}
      >
        {/* <div
          className="TableInnerHeader"
          style={{
            border: "1px solid #e8eaed",
            borderBottom: "0px",
            padding: "12px",
            color: "#5C677D",
          }}
        >
          <h3>Access details</h3>
        </div> */}
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Name
                </StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  <div className="cellwithchip">
                    {guardianParentsDetails?.firstName}{" "}
                    {guardianParentsDetails?.lastName}
                  </div>
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Relationship
                </StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  <div className="cellwithchip">
                    {capitalizeWords(guardianParentsDetails?.userType) || (
                      <span style={{ color: "#BBBFC9" }}>
                        No data available
                      </span>
                    )}
                  </div>
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left">Address</StyledTableCell>
                <StyledTableCell align="left">
                  {/* 1234 Street Address City, NY 11210 */}
                  {guardianParentsDetails?.address || (
                    <span style={{ color: "#BBBFC9" }}>No data available</span>
                  )}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left">
                  Preferred Language
                </StyledTableCell>
                <StyledTableCell align="left">
                  {" "}
                  {guardianParentsDetails?.preferredLanguage || (
                    <span style={{ color: "#BBBFC9" }}>No data available</span>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default About;
